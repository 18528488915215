export const points = [
  ['826.1', '110.3'],
  ['819.3', '110.3'],
  ['819.3', '117.1'],
  ['812.6', '90'],
  ['812.6', '103.5'],
  ['812.6', '110.3'],
  ['805.8', '90'],
  ['805.8', '103.5'],
  ['805.8', '110.3'],
  ['805.8', '117.1'],
  ['805.8', '123.9'],
  ['805.8', '381.6'],
  ['799', '96.7'],
  ['799', '103.5'],
  ['799', '110.3'],
  ['799', '117.1'],
  ['799', '123.9'],
  ['799', '374.8'],
  ['799', '381.6'],
  ['799', '388.4'],
  ['792.2', '96.7'],
  ['792.2', '103.5'],
  ['792.2', '110.3'],
  ['792.2', '117.1'],
  ['792.2', '123.9'],
  ['792.2', '368'],
  ['792.2', '374.8'],
  ['792.2', '381.6'],
  ['792.2', '388.4'],
  ['792.2', '395.2'],
  ['785.4', '96.7'],
  ['785.4', '103.5'],
  ['785.4', '110.3'],
  ['785.4', '117.1'],
  ['785.4', '123.9'],
  ['785.4', '130.7'],
  ['785.4', '395.2'],
  ['785.4', '402'],
  ['778.7', '96.7'],
  ['778.7', '103.5'],
  ['778.7', '110.3'],
  ['778.7', '117.1'],
  ['778.7', '123.9'],
  ['778.7', '130.7'],
  ['778.7', '334.1'],
  ['778.7', '340.9'],
  ['778.7', '395.2'],
  ['778.7', '402'],
  ['771.9', '96.7'],
  ['771.9', '103.5'],
  ['771.9', '110.3'],
  ['771.9', '117.1'],
  ['771.9', '123.9'],
  ['771.9', '130.7'],
  ['771.9', '334.1'],
  ['771.9', '340.9'],
  ['765.1', '96.7'],
  ['765.1', '103.5'],
  ['765.1', '110.3'],
  ['765.1', '117.1'],
  ['765.1', '123.9'],
  ['765.1', '130.7'],
  ['765.1', '137.4'],
  ['765.1', '144.2'],
  ['765.1', '151'],
  ['758.3', '96.7'],
  ['758.3', '103.5'],
  ['758.3', '110.3'],
  ['758.3', '117.1'],
  ['758.3', '123.9'],
  ['758.3', '137.4'],
  ['758.3', '144.2'],
  ['758.3', '151'],
  ['758.3', '157.8'],
  ['758.3', '307'],
  ['751.5', '96.7'],
  ['751.5', '103.5'],
  ['751.5', '110.3'],
  ['751.5', '117.1'],
  ['751.5', '123.9'],
  ['751.5', '130.7'],
  ['751.5', '151'],
  ['751.5', '307'],
  ['744.7', '90'],
  ['744.7', '96.7'],
  ['744.7', '103.5'],
  ['744.7', '110.3'],
  ['744.7', '117.1'],
  ['744.7', '123.9'],
  ['744.7', '130.7'],
  ['744.7', '300.2'],
  ['744.7', '347.7'],
  ['744.7', '354.5'],
  ['744.7', '361.3'],
  ['738', '76.4'],
  ['738', '90'],
  ['738', '96.7'],
  ['738', '103.5'],
  ['738', '110.3'],
  ['738', '117.1'],
  ['738', '123.9'],
  ['738', '130.7'],
  ['738', '300.2'],
  ['738', '313.8'],
  ['738', '340.9'],
  ['738', '347.7'],
  ['738', '354.5'],
  ['738', '361.3'],
  ['738', '368'],
  ['738', '374.8'],
  ['731.2', '76.4'],
  ['731.2', '90'],
  ['731.2', '96.7'],
  ['731.2', '103.5'],
  ['731.2', '110.3'],
  ['731.2', '117.1'],
  ['731.2', '123.9'],
  ['731.2', '130.7'],
  ['731.2', '300.2'],
  ['731.2', '307'],
  ['731.2', '327.3'],
  ['731.2', '334.1'],
  ['731.2', '340.9'],
  ['731.2', '347.7'],
  ['731.2', '354.5'],
  ['731.2', '361.3'],
  ['731.2', '368'],
  ['731.2', '374.8'],
  ['731.2', '381.6'],
  ['731.2', '388.4'],
  ['724.4', '69.6'],
  ['724.4', '76.4'],
  ['724.4', '83.2'],
  ['724.4', '90'],
  ['724.4', '96.7'],
  ['724.4', '103.5'],
  ['724.4', '110.3'],
  ['724.4', '117.1'],
  ['724.4', '123.9'],
  ['724.4', '130.7'],
  ['724.4', '157.8'],
  ['724.4', '164.6'],
  ['724.4', '171.3'],
  ['724.4', '178.1'],
  ['724.4', '184.9'],
  ['724.4', '300.2'],
  ['724.4', '307'],
  ['724.4', '320.6'],
  ['724.4', '327.3'],
  ['724.4', '334.1'],
  ['724.4', '340.9'],
  ['724.4', '347.7'],
  ['724.4', '354.5'],
  ['724.4', '361.3'],
  ['724.4', '368'],
  ['724.4', '374.8'],
  ['724.4', '381.6'],
  ['717.6', '69.6'],
  ['717.6', '76.4'],
  ['717.6', '83.2'],
  ['717.6', '90'],
  ['717.6', '96.7'],
  ['717.6', '103.5'],
  ['717.6', '110.3'],
  ['717.6', '117.1'],
  ['717.6', '123.9'],
  ['717.6', '130.7'],
  ['717.6', '137.4'],
  ['717.6', '151'],
  ['717.6', '157.8'],
  ['717.6', '164.6'],
  ['717.6', '171.3'],
  ['717.6', '178.1'],
  ['717.6', '184.9'],
  ['717.6', '191.7'],
  ['717.6', '198.5'],
  ['717.6', '205.3'],
  ['717.6', '293.4'],
  ['717.6', '300.2'],
  ['717.6', '307'],
  ['717.6', '327.3'],
  ['717.6', '334.1'],
  ['717.6', '340.9'],
  ['717.6', '347.7'],
  ['717.6', '354.5'],
  ['717.6', '361.3'],
  ['717.6', '368'],
  ['717.6', '374.8'],
  ['710.8', '69.6'],
  ['710.8', '76.4'],
  ['710.8', '90'],
  ['710.8', '96.7'],
  ['710.8', '103.5'],
  ['710.8', '110.3'],
  ['710.8', '117.1'],
  ['710.8', '123.9'],
  ['710.8', '130.7'],
  ['710.8', '137.4'],
  ['710.8', '144.2'],
  ['710.8', '151'],
  ['710.8', '157.8'],
  ['710.8', '164.6'],
  ['710.8', '171.3'],
  ['710.8', '178.1'],
  ['710.8', '198.5'],
  ['710.8', '205.3'],
  ['710.8', '293.4'],
  ['710.8', '300.2'],
  ['710.8', '307'],
  ['710.8', '327.3'],
  ['710.8', '334.1'],
  ['710.8', '340.9'],
  ['710.8', '347.7'],
  ['710.8', '354.5'],
  ['710.8', '361.3'],
  ['710.8', '368'],
  ['704', '90'],
  ['704', '96.7'],
  ['704', '103.5'],
  ['704', '110.3'],
  ['704', '117.1'],
  ['704', '123.9'],
  ['704', '130.7'],
  ['704', '137.4'],
  ['704', '144.2'],
  ['704', '151'],
  ['704', '157.8'],
  ['704', '164.6'],
  ['704', '171.3'],
  ['704', '178.1'],
  ['704', '184.9'],
  ['704', '205.3'],
  ['704', '293.4'],
  ['704', '300.2'],
  ['704', '307'],
  ['704', '320.6'],
  ['704', '327.3'],
  ['704', '334.1'],
  ['704', '340.9'],
  ['704', '347.7'],
  ['704', '354.5'],
  ['704', '361.3'],
  ['704', '368'],
  ['697.3', '90'],
  ['697.3', '96.7'],
  ['697.3', '103.5'],
  ['697.3', '110.3'],
  ['697.3', '117.1'],
  ['697.3', '123.9'],
  ['697.3', '130.7'],
  ['697.3', '137.4'],
  ['697.3', '144.2'],
  ['697.3', '151'],
  ['697.3', '157.8'],
  ['697.3', '164.6'],
  ['697.3', '171.3'],
  ['697.3', '178.1'],
  ['697.3', '184.9'],
  ['697.3', '205.3'],
  ['697.3', '212'],
  ['697.3', '218.8'],
  ['697.3', '293.4'],
  ['697.3', '307'],
  ['697.3', '313.8'],
  ['697.3', '320.6'],
  ['697.3', '327.3'],
  ['697.3', '334.1'],
  ['697.3', '340.9'],
  ['697.3', '347.7'],
  ['697.3', '354.5'],
  ['697.3', '361.3'],
  ['690.5', '83.2'],
  ['690.5', '90'],
  ['690.5', '96.7'],
  ['690.5', '103.5'],
  ['690.5', '110.3'],
  ['690.5', '117.1'],
  ['690.5', '123.9'],
  ['690.5', '130.7'],
  ['690.5', '137.4'],
  ['690.5', '144.2'],
  ['690.5', '151'],
  ['690.5', '157.8'],
  ['690.5', '164.6'],
  ['690.5', '171.3'],
  ['690.5', '178.1'],
  ['690.5', '184.9'],
  ['690.5', '191.7'],
  ['690.5', '198.5'],
  ['690.5', '205.3'],
  ['690.5', '225.6'],
  ['690.5', '286.6'],
  ['690.5', '293.4'],
  ['690.5', '320.6'],
  ['690.5', '327.3'],
  ['690.5', '334.1'],
  ['690.5', '340.9'],
  ['690.5', '347.7'],
  ['690.5', '354.5'],
  ['690.5', '361.3'],
  ['683.7', '83.2'],
  ['683.7', '90'],
  ['683.7', '96.7'],
  ['683.7', '103.5'],
  ['683.7', '110.3'],
  ['683.7', '117.1'],
  ['683.7', '123.9'],
  ['683.7', '130.7'],
  ['683.7', '137.4'],
  ['683.7', '144.2'],
  ['683.7', '151'],
  ['683.7', '157.8'],
  ['683.7', '164.6'],
  ['683.7', '171.3'],
  ['683.7', '178.1'],
  ['683.7', '184.9'],
  ['683.7', '191.7'],
  ['683.7', '198.5'],
  ['683.7', '259.5'],
  ['683.7', '266.3'],
  ['683.7', '273.1'],
  ['683.7', '286.6'],
  ['683.7', '293.4'],
  ['683.7', '300.2'],
  ['683.7', '307'],
  ['683.7', '320.6'],
  ['683.7', '327.3'],
  ['683.7', '334.1'],
  ['683.7', '340.9'],
  ['683.7', '347.7'],
  ['683.7', '354.5'],
  ['683.7', '361.3'],
  ['676.9', '83.2'],
  ['676.9', '90'],
  ['676.9', '96.7'],
  ['676.9', '103.5'],
  ['676.9', '110.3'],
  ['676.9', '117.1'],
  ['676.9', '123.9'],
  ['676.9', '130.7'],
  ['676.9', '137.4'],
  ['676.9', '144.2'],
  ['676.9', '151'],
  ['676.9', '157.8'],
  ['676.9', '164.6'],
  ['676.9', '171.3'],
  ['676.9', '178.1'],
  ['676.9', '184.9'],
  ['676.9', '191.7'],
  ['676.9', '246'],
  ['676.9', '252.7'],
  ['676.9', '259.5'],
  ['676.9', '266.3'],
  ['676.9', '273.1'],
  ['676.9', '286.6'],
  ['676.9', '293.4'],
  ['676.9', '300.2'],
  ['676.9', '307'],
  ['676.9', '313.8'],
  ['676.9', '327.3'],
  ['676.9', '334.1'],
  ['676.9', '340.9'],
  ['676.9', '347.7'],
  ['676.9', '354.5'],
  ['676.9', '361.3'],
  ['676.9', '368'],
  ['670.1', '83.2'],
  ['670.1', '90'],
  ['670.1', '96.7'],
  ['670.1', '103.5'],
  ['670.1', '110.3'],
  ['670.1', '117.1'],
  ['670.1', '123.9'],
  ['670.1', '130.7'],
  ['670.1', '137.4'],
  ['670.1', '144.2'],
  ['670.1', '151'],
  ['670.1', '157.8'],
  ['670.1', '164.6'],
  ['670.1', '171.3'],
  ['670.1', '178.1'],
  ['670.1', '184.9'],
  ['670.1', '191.7'],
  ['670.1', '198.5'],
  ['670.1', '205.3'],
  ['670.1', '212'],
  ['670.1', '218.8'],
  ['670.1', '225.6'],
  ['670.1', '232.4'],
  ['670.1', '246'],
  ['670.1', '252.7'],
  ['670.1', '259.5'],
  ['670.1', '286.6'],
  ['670.1', '293.4'],
  ['670.1', '300.2'],
  ['670.1', '307'],
  ['670.1', '313.8'],
  ['670.1', '327.3'],
  ['670.1', '334.1'],
  ['670.1', '340.9'],
  ['670.1', '347.7'],
  ['670.1', '354.5'],
  ['670.1', '361.3'],
  ['670.1', '368'],
  ['663.4', '83.2'],
  ['663.4', '90'],
  ['663.4', '96.7'],
  ['663.4', '103.5'],
  ['663.4', '110.3'],
  ['663.4', '117.1'],
  ['663.4', '123.9'],
  ['663.4', '130.7'],
  ['663.4', '137.4'],
  ['663.4', '144.2'],
  ['663.4', '151'],
  ['663.4', '157.8'],
  ['663.4', '164.6'],
  ['663.4', '171.3'],
  ['663.4', '178.1'],
  ['663.4', '184.9'],
  ['663.4', '191.7'],
  ['663.4', '198.5'],
  ['663.4', '205.3'],
  ['663.4', '212'],
  ['663.4', '218.8'],
  ['663.4', '225.6'],
  ['663.4', '232.4'],
  ['663.4', '273.1'],
  ['663.4', '279.9'],
  ['663.4', '286.6'],
  ['663.4', '293.4'],
  ['663.4', '307'],
  ['663.4', '340.9'],
  ['663.4', '347.7'],
  ['663.4', '354.5'],
  ['663.4', '361.3'],
  ['663.4', '368'],
  ['656.6', '69.6'],
  ['656.6', '76.4'],
  ['656.6', '83.2'],
  ['656.6', '90'],
  ['656.6', '96.7'],
  ['656.6', '103.5'],
  ['656.6', '110.3'],
  ['656.6', '117.1'],
  ['656.6', '123.9'],
  ['656.6', '130.7'],
  ['656.6', '137.4'],
  ['656.6', '144.2'],
  ['656.6', '151'],
  ['656.6', '157.8'],
  ['656.6', '164.6'],
  ['656.6', '171.3'],
  ['656.6', '178.1'],
  ['656.6', '184.9'],
  ['656.6', '191.7'],
  ['656.6', '198.5'],
  ['656.6', '205.3'],
  ['656.6', '212'],
  ['656.6', '218.8'],
  ['656.6', '225.6'],
  ['656.6', '232.4'],
  ['656.6', '279.9'],
  ['656.6', '286.6'],
  ['656.6', '293.4'],
  ['656.6', '307'],
  ['656.6', '340.9'],
  ['656.6', '347.7'],
  ['656.6', '354.5'],
  ['649.8', '69.6'],
  ['649.8', '76.4'],
  ['649.8', '83.2'],
  ['649.8', '90'],
  ['649.8', '96.7'],
  ['649.8', '103.5'],
  ['649.8', '110.3'],
  ['649.8', '117.1'],
  ['649.8', '123.9'],
  ['649.8', '130.7'],
  ['649.8', '137.4'],
  ['649.8', '144.2'],
  ['649.8', '151'],
  ['649.8', '157.8'],
  ['649.8', '164.6'],
  ['649.8', '171.3'],
  ['649.8', '178.1'],
  ['649.8', '184.9'],
  ['649.8', '191.7'],
  ['649.8', '198.5'],
  ['649.8', '205.3'],
  ['649.8', '212'],
  ['649.8', '218.8'],
  ['649.8', '225.6'],
  ['649.8', '232.4'],
  ['649.8', '239.2'],
  ['649.8', '246'],
  ['649.8', '286.6'],
  ['649.8', '293.4'],
  ['649.8', '307'],
  ['643', '69.6'],
  ['643', '76.4'],
  ['643', '83.2'],
  ['643', '90'],
  ['643', '96.7'],
  ['643', '103.5'],
  ['643', '110.3'],
  ['643', '117.1'],
  ['643', '123.9'],
  ['643', '130.7'],
  ['643', '137.4'],
  ['643', '144.2'],
  ['643', '151'],
  ['643', '157.8'],
  ['643', '164.6'],
  ['643', '171.3'],
  ['643', '178.1'],
  ['643', '184.9'],
  ['643', '191.7'],
  ['643', '198.5'],
  ['643', '205.3'],
  ['643', '212'],
  ['643', '218.8'],
  ['643', '225.6'],
  ['643', '232.4'],
  ['643', '239.2'],
  ['643', '252.7'],
  ['643', '259.5'],
  ['643', '300.2'],
  ['643', '307'],
  ['636.2', '56'],
  ['636.2', '62.8'],
  ['636.2', '69.6'],
  ['636.2', '76.4'],
  ['636.2', '83.2'],
  ['636.2', '90'],
  ['636.2', '96.7'],
  ['636.2', '103.5'],
  ['636.2', '110.3'],
  ['636.2', '117.1'],
  ['636.2', '123.9'],
  ['636.2', '130.7'],
  ['636.2', '137.4'],
  ['636.2', '144.2'],
  ['636.2', '151'],
  ['636.2', '157.8'],
  ['636.2', '164.6'],
  ['636.2', '171.3'],
  ['636.2', '178.1'],
  ['636.2', '184.9'],
  ['636.2', '191.7'],
  ['636.2', '198.5'],
  ['636.2', '205.3'],
  ['636.2', '212'],
  ['636.2', '218.8'],
  ['636.2', '225.6'],
  ['636.2', '232.4'],
  ['636.2', '239.2'],
  ['636.2', '246'],
  ['636.2', '252.7'],
  ['636.2', '259.5'],
  ['636.2', '266.3'],
  ['636.2', '279.9'],
  ['636.2', '286.6'],
  ['636.2', '293.4'],
  ['636.2', '300.2'],
  ['629.4', '56'],
  ['629.4', '62.8'],
  ['629.4', '69.6'],
  ['629.4', '76.4'],
  ['629.4', '83.2'],
  ['629.4', '90'],
  ['629.4', '96.7'],
  ['629.4', '103.5'],
  ['629.4', '110.3'],
  ['629.4', '117.1'],
  ['629.4', '123.9'],
  ['629.4', '130.7'],
  ['629.4', '137.4'],
  ['629.4', '144.2'],
  ['629.4', '151'],
  ['629.4', '157.8'],
  ['629.4', '164.6'],
  ['629.4', '171.3'],
  ['629.4', '178.1'],
  ['629.4', '184.9'],
  ['629.4', '191.7'],
  ['629.4', '198.5'],
  ['629.4', '205.3'],
  ['629.4', '212'],
  ['629.4', '218.8'],
  ['629.4', '225.6'],
  ['629.4', '232.4'],
  ['629.4', '239.2'],
  ['629.4', '246'],
  ['629.4', '252.7'],
  ['629.4', '259.5'],
  ['629.4', '273.1'],
  ['629.4', '279.9'],
  ['629.4', '286.6'],
  ['629.4', '293.4'],
  ['622.7', '49.3'],
  ['622.7', '56'],
  ['622.7', '69.6'],
  ['622.7', '76.4'],
  ['622.7', '83.2'],
  ['622.7', '90'],
  ['622.7', '96.7'],
  ['622.7', '103.5'],
  ['622.7', '110.3'],
  ['622.7', '117.1'],
  ['622.7', '123.9'],
  ['622.7', '130.7'],
  ['622.7', '137.4'],
  ['622.7', '144.2'],
  ['622.7', '151'],
  ['622.7', '157.8'],
  ['622.7', '164.6'],
  ['622.7', '171.3'],
  ['622.7', '178.1'],
  ['622.7', '184.9'],
  ['622.7', '191.7'],
  ['622.7', '198.5'],
  ['622.7', '205.3'],
  ['622.7', '212'],
  ['622.7', '218.8'],
  ['622.7', '225.6'],
  ['622.7', '232.4'],
  ['622.7', '239.2'],
  ['622.7', '246'],
  ['622.7', '252.7'],
  ['622.7', '259.5'],
  ['622.7', '266.3'],
  ['622.7', '273.1'],
  ['622.7', '279.9'],
  ['622.7', '286.6'],
  ['615.9', '42.5'],
  ['615.9', '49.3'],
  ['615.9', '56'],
  ['615.9', '69.6'],
  ['615.9', '76.4'],
  ['615.9', '83.2'],
  ['615.9', '90'],
  ['615.9', '96.7'],
  ['615.9', '103.5'],
  ['615.9', '110.3'],
  ['615.9', '117.1'],
  ['615.9', '123.9'],
  ['615.9', '130.7'],
  ['615.9', '137.4'],
  ['615.9', '144.2'],
  ['615.9', '151'],
  ['615.9', '157.8'],
  ['615.9', '164.6'],
  ['615.9', '171.3'],
  ['615.9', '178.1'],
  ['615.9', '184.9'],
  ['615.9', '191.7'],
  ['615.9', '198.5'],
  ['615.9', '205.3'],
  ['615.9', '212'],
  ['615.9', '218.8'],
  ['615.9', '225.6'],
  ['615.9', '232.4'],
  ['615.9', '239.2'],
  ['615.9', '246'],
  ['615.9', '279.9'],
  ['609.1', '49.3'],
  ['609.1', '69.6'],
  ['609.1', '76.4'],
  ['609.1', '83.2'],
  ['609.1', '90'],
  ['609.1', '96.7'],
  ['609.1', '103.5'],
  ['609.1', '110.3'],
  ['609.1', '117.1'],
  ['609.1', '123.9'],
  ['609.1', '130.7'],
  ['609.1', '137.4'],
  ['609.1', '144.2'],
  ['609.1', '151'],
  ['609.1', '157.8'],
  ['609.1', '164.6'],
  ['609.1', '171.3'],
  ['609.1', '178.1'],
  ['609.1', '184.9'],
  ['609.1', '191.7'],
  ['609.1', '198.5'],
  ['609.1', '205.3'],
  ['609.1', '212'],
  ['609.1', '218.8'],
  ['609.1', '225.6'],
  ['609.1', '232.4'],
  ['609.1', '239.2'],
  ['602.3', '76.4'],
  ['602.3', '83.2'],
  ['602.3', '90'],
  ['602.3', '96.7'],
  ['602.3', '103.5'],
  ['602.3', '110.3'],
  ['602.3', '117.1'],
  ['602.3', '123.9'],
  ['602.3', '130.7'],
  ['602.3', '137.4'],
  ['602.3', '144.2'],
  ['602.3', '151'],
  ['602.3', '157.8'],
  ['602.3', '164.6'],
  ['602.3', '171.3'],
  ['602.3', '178.1'],
  ['602.3', '184.9'],
  ['602.3', '191.7'],
  ['602.3', '198.5'],
  ['602.3', '205.3'],
  ['602.3', '212'],
  ['602.3', '218.8'],
  ['602.3', '225.6'],
  ['602.3', '232.4'],
  ['602.3', '239.2'],
  ['595.5', '76.4'],
  ['595.5', '83.2'],
  ['595.5', '90'],
  ['595.5', '96.7'],
  ['595.5', '103.5'],
  ['595.5', '110.3'],
  ['595.5', '117.1'],
  ['595.5', '123.9'],
  ['595.5', '130.7'],
  ['595.5', '137.4'],
  ['595.5', '144.2'],
  ['595.5', '151'],
  ['595.5', '157.8'],
  ['595.5', '164.6'],
  ['595.5', '171.3'],
  ['595.5', '178.1'],
  ['595.5', '184.9'],
  ['595.5', '191.7'],
  ['595.5', '198.5'],
  ['595.5', '205.3'],
  ['595.5', '212'],
  ['595.5', '218.8'],
  ['595.5', '225.6'],
  ['595.5', '232.4'],
  ['595.5', '239.2'],
  ['588.7', '83.2'],
  ['588.7', '90'],
  ['588.7', '96.7'],
  ['588.7', '103.5'],
  ['588.7', '110.3'],
  ['588.7', '117.1'],
  ['588.7', '123.9'],
  ['588.7', '130.7'],
  ['588.7', '137.4'],
  ['588.7', '144.2'],
  ['588.7', '151'],
  ['588.7', '157.8'],
  ['588.7', '164.6'],
  ['588.7', '171.3'],
  ['588.7', '178.1'],
  ['588.7', '184.9'],
  ['588.7', '191.7'],
  ['588.7', '198.5'],
  ['588.7', '205.3'],
  ['588.7', '212'],
  ['588.7', '218.8'],
  ['588.7', '225.6'],
  ['588.7', '232.4'],
  ['588.7', '239.2'],
  ['588.7', '246'],
  ['582', '83.2'],
  ['582', '90'],
  ['582', '96.7'],
  ['582', '103.5'],
  ['582', '110.3'],
  ['582', '117.1'],
  ['582', '123.9'],
  ['582', '130.7'],
  ['582', '137.4'],
  ['582', '144.2'],
  ['582', '151'],
  ['582', '157.8'],
  ['582', '164.6'],
  ['582', '171.3'],
  ['582', '178.1'],
  ['582', '184.9'],
  ['582', '191.7'],
  ['582', '198.5'],
  ['582', '205.3'],
  ['582', '212'],
  ['582', '218.8'],
  ['582', '225.6'],
  ['582', '232.4'],
  ['582', '239.2'],
  ['582', '246'],
  ['582', '252.7'],
  ['582', '259.5'],
  ['582', '273.1'],
  ['575.2', '83.2'],
  ['575.2', '90'],
  ['575.2', '96.7'],
  ['575.2', '103.5'],
  ['575.2', '110.3'],
  ['575.2', '117.1'],
  ['575.2', '123.9'],
  ['575.2', '130.7'],
  ['575.2', '137.4'],
  ['575.2', '144.2'],
  ['575.2', '151'],
  ['575.2', '157.8'],
  ['575.2', '164.6'],
  ['575.2', '171.3'],
  ['575.2', '178.1'],
  ['575.2', '184.9'],
  ['575.2', '191.7'],
  ['575.2', '198.5'],
  ['575.2', '205.3'],
  ['575.2', '212'],
  ['575.2', '218.8'],
  ['575.2', '225.6'],
  ['575.2', '232.4'],
  ['575.2', '239.2'],
  ['575.2', '246'],
  ['575.2', '252.7'],
  ['575.2', '259.5'],
  ['575.2', '266.3'],
  ['568.4', '83.2'],
  ['568.4', '90'],
  ['568.4', '96.7'],
  ['568.4', '103.5'],
  ['568.4', '110.3'],
  ['568.4', '117.1'],
  ['568.4', '123.9'],
  ['568.4', '130.7'],
  ['568.4', '137.4'],
  ['568.4', '144.2'],
  ['568.4', '151'],
  ['568.4', '157.8'],
  ['568.4', '164.6'],
  ['568.4', '171.3'],
  ['568.4', '178.1'],
  ['568.4', '184.9'],
  ['568.4', '191.7'],
  ['568.4', '198.5'],
  ['568.4', '205.3'],
  ['568.4', '212'],
  ['568.4', '218.8'],
  ['568.4', '225.6'],
  ['568.4', '232.4'],
  ['568.4', '239.2'],
  ['568.4', '246'],
  ['568.4', '252.7'],
  ['568.4', '259.5'],
  ['561.6', '83.2'],
  ['561.6', '90'],
  ['561.6', '96.7'],
  ['561.6', '103.5'],
  ['561.6', '110.3'],
  ['561.6', '117.1'],
  ['561.6', '123.9'],
  ['561.6', '130.7'],
  ['561.6', '137.4'],
  ['561.6', '144.2'],
  ['561.6', '151'],
  ['561.6', '157.8'],
  ['561.6', '164.6'],
  ['561.6', '171.3'],
  ['561.6', '178.1'],
  ['561.6', '184.9'],
  ['561.6', '191.7'],
  ['561.6', '198.5'],
  ['561.6', '205.3'],
  ['561.6', '212'],
  ['561.6', '218.8'],
  ['561.6', '225.6'],
  ['561.6', '232.4'],
  ['561.6', '239.2'],
  ['554.8', '69.6'],
  ['554.8', '83.2'],
  ['554.8', '90'],
  ['554.8', '96.7'],
  ['541.3', '49.3'],
  ['548.1', '49.3'],
  ['534.5', '42.5'],
  ['534.5', '49.3'],
  ['527.7', '42.5'],
  ['527.7', '49.3'],
  ['520.9', '49.3'],
  ['507.4', '49.3'],
  ['500.6', '42.5'],
  ['500.6', '49.3'],
  ['493.8', '42.5'],
  ['554.8', '103.5'],
  ['554.8', '110.3'],
  ['554.8', '117.1'],
  ['554.8', '123.9'],
  ['554.8', '130.7'],
  ['554.8', '137.4'],
  ['554.8', '144.2'],
  ['554.8', '151'],
  ['554.8', '157.8'],
  ['554.8', '164.6'],
  ['554.8', '171.3'],
  ['554.8', '178.1'],
  ['554.8', '184.9'],
  ['554.8', '191.7'],
  ['554.8', '198.5'],
  ['554.8', '205.3'],
  ['554.8', '212'],
  ['554.8', '218.8'],
  ['554.8', '225.6'],
  ['554.8', '232.4'],
  ['554.8', '239.2'],
  ['548.1', '69.6'],
  ['548.1', '90'],
  ['548.1', '96.7'],
  ['548.1', '103.5'],
  ['548.1', '110.3'],
  ['548.1', '117.1'],
  ['548.1', '123.9'],
  ['548.1', '130.7'],
  ['548.1', '137.4'],
  ['548.1', '144.2'],
  ['548.1', '151'],
  ['548.1', '157.8'],
  ['548.1', '164.6'],
  ['548.1', '171.3'],
  ['548.1', '178.1'],
  ['548.1', '184.9'],
  ['548.1', '191.7'],
  ['548.1', '198.5'],
  ['548.1', '205.3'],
  ['548.1', '212'],
  ['548.1', '218.8'],
  ['548.1', '225.6'],
  ['541.3', '69.6'],
  ['541.3', '96.7'],
  ['541.3', '103.5'],
  ['541.3', '110.3'],
  ['541.3', '117.1'],
  ['541.3', '123.9'],
  ['541.3', '130.7'],
  ['541.3', '137.4'],
  ['541.3', '144.2'],
  ['541.3', '151'],
  ['541.3', '157.8'],
  ['541.3', '164.6'],
  ['541.3', '171.3'],
  ['541.3', '178.1'],
  ['541.3', '184.9'],
  ['541.3', '191.7'],
  ['541.3', '198.5'],
  ['541.3', '205.3'],
  ['541.3', '212'],
  ['541.3', '218.8'],
  ['541.3', '225.6'],
  ['534.5', '69.6'],
  ['534.5', '76.4'],
  ['534.5', '96.7'],
  ['534.5', '103.5'],
  ['534.5', '110.3'],
  ['534.5', '117.1'],
  ['534.5', '123.9'],
  ['534.5', '130.7'],
  ['534.5', '137.4'],
  ['534.5', '144.2'],
  ['534.5', '151'],
  ['534.5', '157.8'],
  ['534.5', '164.6'],
  ['534.5', '171.3'],
  ['534.5', '178.1'],
  ['534.5', '184.9'],
  ['534.5', '191.7'],
  ['534.5', '198.5'],
  ['534.5', '205.3'],
  ['534.5', '212'],
  ['534.5', '218.8'],
  ['534.5', '225.6'],
  ['527.7', '76.4'],
  ['527.7', '83.2'],
  ['527.7', '90'],
  ['527.7', '103.5'],
  ['527.7', '110.3'],
  ['527.7', '117.1'],
  ['527.7', '123.9'],
  ['527.7', '130.7'],
  ['527.7', '137.4'],
  ['527.7', '144.2'],
  ['527.7', '151'],
  ['527.7', '157.8'],
  ['527.7', '164.6'],
  ['527.7', '171.3'],
  ['527.7', '178.1'],
  ['527.7', '184.9'],
  ['527.7', '191.7'],
  ['527.7', '198.5'],
  ['527.7', '205.3'],
  ['527.7', '212'],
  ['527.7', '218.8'],
  ['527.7', '225.6'],
  ['527.7', '239.2'],
  ['520.9', '83.2'],
  ['520.9', '90'],
  ['520.9', '103.5'],
  ['520.9', '110.3'],
  ['520.9', '117.1'],
  ['520.9', '123.9'],
  ['520.9', '130.7'],
  ['520.9', '137.4'],
  ['520.9', '144.2'],
  ['520.9', '151'],
  ['520.9', '157.8'],
  ['520.9', '164.6'],
  ['520.9', '171.3'],
  ['520.9', '178.1'],
  ['520.9', '184.9'],
  ['520.9', '191.7'],
  ['520.9', '198.5'],
  ['520.9', '205.3'],
  ['520.9', '212'],
  ['520.9', '218.8'],
  ['520.9', '225.6'],
  ['520.9', '239.2'],
  ['520.9', '246'],
  ['514.1', '90'],
  ['514.1', '103.5'],
  ['514.1', '110.3'],
  ['514.1', '117.1'],
  ['514.1', '123.9'],
  ['514.1', '130.7'],
  ['514.1', '137.4'],
  ['514.1', '144.2'],
  ['514.1', '151'],
  ['514.1', '157.8'],
  ['514.1', '164.6'],
  ['514.1', '171.3'],
  ['514.1', '178.1'],
  ['514.1', '184.9'],
  ['514.1', '191.7'],
  ['514.1', '198.5'],
  ['514.1', '205.3'],
  ['514.1', '212'],
  ['514.1', '218.8'],
  ['514.1', '232.4'],
  ['514.1', '239.2'],
  ['514.1', '246'],
  ['514.1', '252.7'],
  ['514.1', '266.3'],
  ['514.1', '273.1'],
  ['514.1', '320.6'],
  ['507.4', '96.7'],
  ['507.4', '103.5'],
  ['507.4', '110.3'],
  ['507.4', '117.1'],
  ['507.4', '123.9'],
  ['507.4', '130.7'],
  ['507.4', '137.4'],
  ['507.4', '144.2'],
  ['507.4', '151'],
  ['507.4', '157.8'],
  ['507.4', '164.6'],
  ['507.4', '171.3'],
  ['507.4', '178.1'],
  ['507.4', '184.9'],
  ['507.4', '191.7'],
  ['507.4', '198.5'],
  ['507.4', '205.3'],
  ['507.4', '212'],
  ['507.4', '218.8'],
  ['507.4', '225.6'],
  ['507.4', '232.4'],
  ['507.4', '239.2'],
  ['507.4', '246'],
  ['507.4', '252.7'],
  ['507.4', '266.3'],
  ['507.4', '273.1'],
  ['507.4', '320.6'],
  ['507.4', '327.3'],
  ['507.4', '334.1'],
  ['507.4', '340.9'],
  ['500.6', '103.5'],
  ['500.6', '110.3'],
  ['500.6', '117.1'],
  ['500.6', '123.9'],
  ['500.6', '130.7'],
  ['500.6', '137.4'],
  ['500.6', '144.2'],
  ['500.6', '151'],
  ['500.6', '157.8'],
  ['500.6', '164.6'],
  ['500.6', '171.3'],
  ['500.6', '178.1'],
  ['500.6', '184.9'],
  ['405.6', '157.8'],
  ['398.8', '151'],
  ['392.1', '130.7'],
  ['398.8', '130.7'],
  ['392.1', '137.4'],
  ['392.1', '144.2'],
  ['392.1', '151'],
  ['392.1', '157.8'],
  ['385.3', '137.4'],
  ['385.3', '144.2'],
  ['385.3', '151'],
  ['385.3', '157.8'],
  ['378.5', '123.9'],
  ['378.5', '144.2'],
  ['378.5', '151'],
  ['371.7', '151'],
  ['500.6', '191.7'],
  ['500.6', '198.5'],
  ['500.6', '205.3'],
  ['500.6', '212'],
  ['500.6', '218.8'],
  ['500.6', '225.6'],
  ['500.6', '232.4'],
  ['500.6', '239.2'],
  ['500.6', '246'],
  ['500.6', '252.7'],
  ['500.6', '259.5'],
  ['500.6', '266.3'],
  ['500.6', '273.1'],
  ['500.6', '279.9'],
  ['500.6', '327.3'],
  ['500.6', '334.1'],
  ['500.6', '340.9'],
  ['500.6', '347.7'],
  ['493.8', '103.5'],
  ['493.8', '110.3'],
  ['493.8', '117.1'],
  ['493.8', '123.9'],
  ['493.8', '130.7'],
  ['493.8', '137.4'],
  ['493.8', '144.2'],
  ['493.8', '151'],
  ['493.8', '157.8'],
  ['493.8', '164.6'],
  ['493.8', '171.3'],
  ['493.8', '178.1'],
  ['493.8', '184.9'],
  ['493.8', '191.7'],
  ['493.8', '198.5'],
  ['493.8', '205.3'],
  ['493.8', '212'],
  ['493.8', '218.8'],
  ['493.8', '225.6'],
  ['493.8', '232.4'],
  ['493.8', '239.2'],
  ['493.8', '246'],
  ['493.8', '259.5'],
  ['493.8', '266.3'],
  ['493.8', '273.1'],
  ['493.8', '279.9'],
  ['493.8', '286.6'],
  ['487', '103.5'],
  ['487', '110.3'],
  ['487', '117.1'],
  ['487', '123.9'],
  ['487', '130.7'],
  ['487', '137.4'],
  ['487', '144.2'],
  ['487', '151'],
  ['487', '157.8'],
  ['487', '164.6'],
  ['487', '171.3'],
  ['487', '178.1'],
  ['487', '191.7'],
  ['487', '198.5'],
  ['487', '205.3'],
  ['487', '212'],
  ['487', '218.8'],
  ['487', '225.6'],
  ['487', '232.4'],
  ['487', '239.2'],
  ['487', '252.7'],
  ['487', '259.5'],
  ['487', '266.3'],
  ['487', '273.1'],
  ['487', '279.9'],
  ['487', '286.6'],
  ['487', '293.4'],
  ['487', '300.2'],
  ['487', '307'],
  ['487', '313.8'],
  ['487', '320.6'],
  ['487', '327.3'],
  ['480.2', '103.5'],
  ['480.2', '110.3'],
  ['480.2', '117.1'],
  ['480.2', '123.9'],
  ['480.2', '130.7'],
  ['480.2', '137.4'],
  ['480.2', '144.2'],
  ['480.2', '151'],
  ['480.2', '157.8'],
  ['358.1', '110.3'],
  ['351.4', '110.3'],
  ['351.4', '117.1'],
  ['344.6', '110.3'],
  ['344.6', '117.1'],
  ['480.2', '164.6'],
  ['480.2', '171.3'],
  ['480.2', '178.1'],
  ['480.2', '191.7'],
  ['480.2', '198.5'],
  ['480.2', '205.3'],
  ['480.2', '212'],
  ['480.2', '218.8'],
  ['480.2', '225.6'],
  ['480.2', '239.2'],
  ['480.2', '246'],
  ['480.2', '252.7'],
  ['480.2', '259.5'],
  ['480.2', '266.3'],
  ['480.2', '273.1'],
  ['480.2', '279.9'],
  ['480.2', '286.6'],
  ['480.2', '293.4'],
  ['480.2', '300.2'],
  ['480.2', '307'],
  ['480.2', '313.8'],
  ['480.2', '320.6'],
  ['480.2', '327.3'],
  ['480.2', '334.1'],
  ['480.2', '340.9'],
  ['480.2', '347.7'],
  ['473.4', '103.5'],
  ['473.4', '110.3'],
  ['473.4', '117.1'],
  ['473.4', '123.9'],
  ['473.4', '130.7'],
  ['473.4', '137.4'],
  ['473.4', '144.2'],
  ['473.4', '151'],
  ['473.4', '157.8'],
  ['473.4', '164.6'],
  ['473.4', '171.3'],
  ['473.4', '178.1'],
  ['473.4', '191.7'],
  ['473.4', '198.5'],
  ['473.4', '205.3'],
  ['473.4', '218.8'],
  ['473.4', '225.6'],
  ['473.4', '232.4'],
  ['473.4', '239.2'],
  ['473.4', '246'],
  ['473.4', '252.7'],
  ['473.4', '259.5'],
  ['473.4', '266.3'],
  ['473.4', '273.1'],
  ['473.4', '279.9'],
  ['473.4', '286.6'],
  ['473.4', '293.4'],
  ['473.4', '300.2'],
  ['473.4', '307'],
  ['473.4', '313.8'],
  ['473.4', '320.6'],
  ['473.4', '327.3'],
  ['473.4', '334.1'],
  ['473.4', '340.9'],
  ['473.4', '347.7'],
  ['466.7', '90'],
  ['466.7', '96.7'],
  ['466.7', '103.5'],
  ['466.7', '110.3'],
  ['466.7', '117.1'],
  ['466.7', '123.9'],
  ['466.7', '130.7'],
  ['466.7', '137.4'],
  ['466.7', '144.2'],
  ['466.7', '151'],
  ['466.7', '157.8'],
  ['466.7', '164.6'],
  ['466.7', '171.3'],
  ['466.7', '178.1'],
  ['466.7', '191.7'],
  ['466.7', '198.5'],
  ['466.7', '218.8'],
  ['466.7', '225.6'],
  ['466.7', '232.4'],
  ['466.7', '239.2'],
  ['466.7', '246'],
  ['466.7', '252.7'],
  ['466.7', '259.5'],
  ['466.7', '266.3'],
  ['466.7', '273.1'],
  ['466.7', '279.9'],
  ['466.7', '286.6'],
  ['466.7', '293.4'],
  ['466.7', '300.2'],
  ['466.7', '307'],
  ['466.7', '313.8'],
  ['466.7', '320.6'],
  ['466.7', '327.3'],
  ['466.7', '334.1'],
  ['466.7', '340.9'],
  ['466.7', '347.7'],
  ['466.7', '354.5'],
  ['466.7', '361.3'],
  ['459.9', '49.3'],
  ['459.9', '90'],
  ['459.9', '96.7'],
  ['459.9', '103.5'],
  ['459.9', '110.3'],
  ['459.9', '117.1'],
  ['459.9', '123.9'],
  ['459.9', '130.7'],
  ['459.9', '137.4'],
  ['459.9', '144.2'],
  ['459.9', '151'],
  ['459.9', '157.8'],
  ['459.9', '164.6'],
  ['459.9', '171.3'],
  ['459.9', '178.1'],
  ['459.9', '184.9'],
  ['459.9', '191.7'],
  ['459.9', '198.5'],
  ['459.9', '218.8'],
  ['459.9', '225.6'],
  ['459.9', '232.4'],
  ['459.9', '239.2'],
  ['459.9', '246'],
  ['459.9', '252.7'],
  ['459.9', '259.5'],
  ['459.9', '266.3'],
  ['459.9', '273.1'],
  ['459.9', '279.9'],
  ['459.9', '286.6'],
  ['459.9', '293.4'],
  ['459.9', '300.2'],
  ['459.9', '307'],
  ['459.9', '313.8'],
  ['459.9', '320.6'],
  ['459.9', '327.3'],
  ['459.9', '334.1'],
  ['459.9', '340.9'],
  ['459.9', '347.7'],
  ['459.9', '354.5'],
  ['459.9', '361.3'],
  ['459.9', '368'],
  ['453.1', '49.3'],
  ['453.1', '62.8'],
  ['453.1', '96.7'],
  ['453.1', '103.5'],
  ['453.1', '110.3'],
  ['453.1', '117.1'],
  ['453.1', '123.9'],
  ['453.1', '130.7'],
  ['453.1', '137.4'],
  ['453.1', '144.2'],
  ['453.1', '151'],
  ['453.1', '157.8'],
  ['453.1', '164.6'],
  ['453.1', '171.3'],
  ['453.1', '178.1'],
  ['453.1', '184.9'],
  ['453.1', '191.7'],
  ['453.1', '198.5'],
  ['453.1', '212'],
  ['453.1', '218.8'],
  ['453.1', '225.6'],
  ['453.1', '232.4'],
  ['453.1', '239.2'],
  ['453.1', '246'],
  ['453.1', '252.7'],
  ['453.1', '259.5'],
  ['453.1', '266.3'],
  ['453.1', '273.1'],
  ['453.1', '279.9'],
  ['453.1', '286.6'],
  ['453.1', '293.4'],
  ['453.1', '300.2'],
  ['453.1', '307'],
  ['453.1', '313.8'],
  ['453.1', '320.6'],
  ['453.1', '327.3'],
  ['453.1', '334.1'],
  ['453.1', '340.9'],
  ['453.1', '347.7'],
  ['453.1', '354.5'],
  ['453.1', '361.3'],
  ['453.1', '368'],
  ['446.3', '49.3'],
  ['446.3', '56'],
  ['446.3', '96.7'],
  ['446.3', '103.5'],
  ['446.3', '110.3'],
  ['446.3', '117.1'],
  ['446.3', '123.9'],
  ['446.3', '144.2'],
  ['446.3', '151'],
  ['446.3', '157.8'],
  ['446.3', '164.6'],
  ['446.3', '171.3'],
  ['446.3', '178.1'],
  ['446.3', '184.9'],
  ['446.3', '191.7'],
  ['446.3', '198.5'],
  ['446.3', '212'],
  ['446.3', '218.8'],
  ['446.3', '225.6'],
  ['446.3', '232.4'],
  ['446.3', '239.2'],
  ['446.3', '246'],
  ['446.3', '252.7'],
  ['446.3', '259.5'],
  ['446.3', '266.3'],
  ['446.3', '273.1'],
  ['446.3', '279.9'],
  ['446.3', '286.6'],
  ['446.3', '293.4'],
  ['446.3', '300.2'],
  ['446.3', '307'],
  ['446.3', '313.8'],
  ['446.3', '320.6'],
  ['446.3', '327.3'],
  ['446.3', '334.1'],
  ['446.3', '340.9'],
  ['446.3', '347.7'],
  ['446.3', '354.5'],
  ['446.3', '361.3'],
  ['446.3', '368'],
  ['439.5', '49.3'],
  ['439.5', '56'],
  ['439.5', '62.8'],
  ['439.5', '96.7'],
  ['439.5', '103.5'],
  ['439.5', '110.3'],
  ['439.5', '117.1'],
  ['439.5', '130.7'],
  ['439.5', '151'],
  ['439.5', '157.8'],
  ['439.5', '164.6'],
  ['439.5', '171.3'],
  ['439.5', '178.1'],
  ['439.5', '184.9'],
  ['439.5', '218.8'],
  ['439.5', '225.6'],
  ['439.5', '232.4'],
  ['439.5', '239.2'],
  ['439.5', '246'],
  ['439.5', '252.7'],
  ['439.5', '259.5'],
  ['439.5', '266.3'],
  ['439.5', '273.1'],
  ['439.5', '279.9'],
  ['439.5', '286.6'],
  ['439.5', '293.4'],
  ['439.5', '300.2'],
  ['439.5', '307'],
  ['439.5', '313.8'],
  ['439.5', '320.6'],
  ['439.5', '327.3'],
  ['439.5', '334.1'],
  ['439.5', '340.9'],
  ['439.5', '347.7'],
  ['439.5', '354.5'],
  ['439.5', '361.3'],
  ['439.5', '368'],
  ['432.8', '56'],
  ['432.8', '62.8'],
  ['432.8', '103.5'],
  ['432.8', '110.3'],
  ['432.8', '117.1'],
  ['432.8', '123.9'],
  ['432.8', '130.7'],
  ['432.8', '137.4'],
  ['432.8', '144.2'],
  ['432.8', '151'],
  ['432.8', '157.8'],
  ['432.8', '164.6'],
  ['432.8', '171.3'],
  ['432.8', '178.1'],
  ['432.8', '191.7'],
  ['432.8', '198.5'],
  ['432.8', '218.8'],
  ['432.8', '225.6'],
  ['432.8', '232.4'],
  ['432.8', '239.2'],
  ['432.8', '246'],
  ['432.8', '252.7'],
  ['432.8', '259.5'],
  ['432.8', '266.3'],
  ['432.8', '273.1'],
  ['432.8', '279.9'],
  ['432.8', '286.6'],
  ['432.8', '293.4'],
  ['432.8', '300.2'],
  ['432.8', '307'],
  ['432.8', '313.8'],
  ['432.8', '320.6'],
  ['432.8', '327.3'],
  ['432.8', '334.1'],
  ['432.8', '340.9'],
  ['432.8', '347.7'],
  ['426', '56'],
  ['426', '110.3'],
  ['426', '117.1'],
  ['426', '123.9'],
  ['426', '130.7'],
  ['426', '137.4'],
  ['426', '151'],
  ['426', '157.8'],
  ['426', '164.6'],
  ['426', '171.3'],
  ['426', '178.1'],
  ['426', '184.9'],
  ['426', '198.5'],
  ['426', '212'],
  ['426', '218.8'],
  ['426', '225.6'],
  ['426', '232.4'],
  ['426', '239.2'],
  ['426', '246'],
  ['426', '252.7'],
  ['426', '259.5'],
  ['426', '266.3'],
  ['426', '273.1'],
  ['426', '279.9'],
  ['426', '286.6'],
  ['426', '293.4'],
  ['426', '300.2'],
  ['426', '320.6'],
  ['426', '327.3'],
  ['419.2', '117.1'],
  ['419.2', '123.9'],
  ['419.2', '130.7'],
  ['419.2', '137.4'],
  ['419.2', '144.2'],
  ['419.2', '151'],
  ['419.2', '157.8'],
  ['419.2', '164.6'],
  ['419.2', '171.3'],
  ['419.2', '178.1'],
  ['419.2', '184.9'],
  ['419.2', '191.7'],
  ['419.2', '205.3'],
  ['419.2', '212'],
  ['419.2', '218.8'],
  ['419.2', '225.6'],
  ['419.2', '232.4'],
  ['419.2', '239.2'],
  ['419.2', '246'],
  ['419.2', '252.7'],
  ['419.2', '259.5'],
  ['419.2', '266.3'],
  ['419.2', '273.1'],
  ['419.2', '279.9'],
  ['419.2', '286.6'],
  ['419.2', '293.4'],
  ['412.4', '123.9'],
  ['412.4', '130.7'],
  ['412.4', '137.4'],
  ['412.4', '157.8'],
  ['412.4', '164.6'],
  ['412.4', '171.3'],
  ['412.4', '178.1'],
  ['412.4', '184.9'],
  ['412.4', '205.3'],
  ['412.4', '212'],
  ['412.4', '218.8'],
  ['412.4', '225.6'],
  ['412.4', '232.4'],
  ['412.4', '239.2'],
  ['412.4', '246'],
  ['412.4', '252.7'],
  ['412.4', '259.5'],
  ['412.4', '266.3'],
  ['412.4', '273.1'],
  ['405.6', '164.6'],
  ['405.6', '171.3'],
  ['405.6', '178.1'],
  ['405.6', '184.9'],
  ['405.6', '205.3'],
  ['405.6', '212'],
  ['405.6', '218.8'],
  ['405.6', '225.6'],
  ['405.6', '232.4'],
  ['405.6', '239.2'],
  ['405.6', '246'],
  ['405.6', '252.7'],
  ['405.6', '259.5'],
  ['405.6', '266.3'],
  ['405.6', '273.1'],
  ['398.8', '164.6'],
  ['398.8', '171.3'],
  ['392.1', '171.3'],
  ['385.3', '171.3'],
  ['398.8', '178.1'],
  ['398.8', '184.9'],
  ['398.8', '191.7'],
  ['398.8', '205.3'],
  ['398.8', '212'],
  ['398.8', '218.8'],
  ['398.8', '225.6'],
  ['398.8', '232.4'],
  ['398.8', '239.2'],
  ['398.8', '246'],
  ['398.8', '252.7'],
  ['398.8', '259.5'],
  ['398.8', '266.3'],
  ['398.8', '273.1'],
  ['392.1', '184.9'],
  ['392.1', '191.7'],
  ['392.1', '198.5'],
  ['392.1', '205.3'],
  ['392.1', '212'],
  ['392.1', '218.8'],
  ['392.1', '225.6'],
  ['392.1', '232.4'],
  ['392.1', '239.2'],
  ['392.1', '246'],
  ['392.1', '252.7'],
  ['392.1', '259.5'],
  ['392.1', '266.3'],
  ['392.1', '273.1'],
  ['385.3', '184.9'],
  ['385.3', '191.7'],
  ['385.3', '198.5'],
  ['385.3', '205.3'],
  ['385.3', '212'],
  ['385.3', '218.8'],
  ['385.3', '225.6'],
  ['385.3', '232.4'],
  ['385.3', '239.2'],
  ['385.3', '246'],
  ['385.3', '252.7'],
  ['385.3', '259.5'],
  ['385.3', '266.3'],
  ['385.3', '273.1'],
  ['385.3', '279.9'],
  ['378.5', '184.9'],
  ['378.5', '191.7'],
  ['378.5', '198.5'],
  ['378.5', '212'],
  ['378.5', '218.8'],
  ['378.5', '225.6'],
  ['378.5', '232.4'],
  ['378.5', '239.2'],
  ['378.5', '246'],
  ['378.5', '252.7'],
  ['378.5', '259.5'],
  ['378.5', '266.3'],
  ['378.5', '273.1'],
  ['378.5', '279.9'],
  ['371.7', '42.5'],
  ['371.7', '225.6'],
  ['371.7', '232.4'],
  ['371.7', '239.2'],
  ['371.7', '246'],
  ['371.7', '252.7'],
  ['371.7', '259.5'],
  ['371.7', '266.3'],
  ['371.7', '273.1'],
  ['364.9', '42.5'],
  ['364.9', '232.4'],
  ['364.9', '239.2'],
  ['364.9', '246'],
  ['364.9', '252.7'],
  ['364.9', '259.5'],
  ['358.1', '42.5'],
  ['358.1', '49.3'],
  ['358.1', '239.2'],
  ['351.4', '35.7'],
  ['351.4', '42.5'],
  ['351.4', '49.3'],
  ['351.4', '56'],
  ['351.4', '62.8'],
  ['351.4', '69.6'],
  ['351.4', '76.4'],
  ['344.6', '35.7'],
  ['344.6', '42.5'],
  ['344.6', '49.3'],
  ['344.6', '56'],
  ['344.6', '62.8'],
  ['344.6', '69.6'],
  ['344.6', '76.4'],
  ['344.6', '83.2'],
  ['344.6', '90'],
  ['344.6', '96.7'],
  ['337.8', '28.9'],
  ['337.8', '35.7'],
  ['337.8', '42.5'],
  ['337.8', '49.3'],
  ['337.8', '56'],
  ['337.8', '62.8'],
  ['337.8', '69.6'],
  ['337.8', '76.4'],
  ['337.8', '83.2'],
  ['337.8', '90'],
  ['337.8', '96.7'],
  ['331', '28.9'],
  ['331', '35.7'],
  ['331', '42.5'],
  ['331', '49.3'],
  ['331', '56'],
  ['331', '62.8'],
  ['331', '69.6'],
  ['331', '76.4'],
  ['331', '83.2'],
  ['331', '90'],
  ['331', '96.7'],
  ['331', '103.5'],
  ['324.2', '28.9'],
  ['324.2', '35.7'],
  ['324.2', '42.5'],
  ['324.2', '49.3'],
  ['324.2', '56'],
  ['324.2', '62.8'],
  ['324.2', '69.6'],
  ['324.2', '76.4'],
  ['324.2', '83.2'],
  ['324.2', '90'],
  ['324.2', '96.7'],
  ['324.2', '103.5'],
  ['317.5', '28.9'],
  ['317.5', '35.7'],
  ['317.5', '42.5'],
  ['317.5', '49.3'],
  ['317.5', '56'],
  ['317.5', '62.8'],
  ['317.5', '69.6'],
  ['317.5', '76.4'],
  ['317.5', '83.2'],
  ['317.5', '90'],
  ['317.5', '96.7'],
  ['317.5', '103.5'],
  ['317.5', '110.3'],
  ['317.5', '300.2'],
  ['317.5', '307'],
  ['310.7', '28.9'],
  ['310.7', '35.7'],
  ['310.7', '42.5'],
  ['310.7', '49.3'],
  ['310.7', '56'],
  ['310.7', '62.8'],
  ['310.7', '69.6'],
  ['310.7', '76.4'],
  ['310.7', '83.2'],
  ['310.7', '90'],
  ['310.7', '96.7'],
  ['310.7', '103.5'],
  ['310.7', '110.3'],
  ['310.7', '300.2'],
  ['310.7', '307'],
  ['310.7', '313.8'],
  ['310.7', '320.6'],
  ['310.7', '327.3'],
  ['303.9', '35.7'],
  ['303.9', '42.5'],
  ['303.9', '49.3'],
  ['303.9', '56'],
  ['303.9', '62.8'],
  ['303.9', '69.6'],
  ['303.9', '76.4'],
  ['303.9', '83.2'],
  ['303.9', '90'],
  ['303.9', '96.7'],
  ['303.9', '103.5'],
  ['303.9', '110.3'],
  ['303.9', '117.1'],
  ['303.9', '123.9'],
  ['303.9', '300.2'],
  ['303.9', '307'],
  ['303.9', '313.8'],
  ['303.9', '320.6'],
  ['303.9', '327.3'],
  ['303.9', '334.1'],
  ['303.9', '340.9'],
  ['297.1', '35.7'],
  ['297.1', '42.5'],
  ['297.1', '49.3'],
  ['297.1', '56'],
  ['297.1', '62.8'],
  ['297.1', '69.6'],
  ['297.1', '76.4'],
  ['297.1', '83.2'],
  ['297.1', '90'],
  ['297.1', '96.7'],
  ['297.1', '103.5'],
  ['297.1', '110.3'],
  ['297.1', '117.1'],
  ['297.1', '123.9'],
  ['297.1', '130.7'],
  ['297.1', '293.4'],
  ['297.1', '300.2'],
  ['297.1', '307'],
  ['297.1', '313.8'],
  ['297.1', '320.6'],
  ['297.1', '327.3'],
  ['297.1', '334.1'],
  ['297.1', '340.9'],
  ['290.3', '35.7'],
  ['290.3', '42.5'],
  ['290.3', '49.3'],
  ['290.3', '56'],
  ['290.3', '62.8'],
  ['290.3', '69.6'],
  ['290.3', '76.4'],
  ['290.3', '83.2'],
  ['290.3', '90'],
  ['290.3', '96.7'],
  ['290.3', '103.5'],
  ['290.3', '110.3'],
  ['290.3', '117.1'],
  ['290.3', '123.9'],
  ['290.3', '130.7'],
  ['290.3', '293.4'],
  ['290.3', '300.2'],
  ['290.3', '307'],
  ['290.3', '313.8'],
  ['290.3', '320.6'],
  ['290.3', '327.3'],
  ['290.3', '334.1'],
  ['290.3', '340.9'],
  ['290.3', '347.7'],
  ['283.5', '35.7'],
  ['283.5', '42.5'],
  ['283.5', '49.3'],
  ['283.5', '56'],
  ['283.5', '62.8'],
  ['283.5', '69.6'],
  ['283.5', '76.4'],
  ['283.5', '83.2'],
  ['283.5', '90'],
  ['283.5', '96.7'],
  ['283.5', '103.5'],
  ['283.5', '110.3'],
  ['283.5', '117.1'],
  ['283.5', '123.9'],
  ['283.5', '279.9'],
  ['283.5', '286.6'],
  ['283.5', '293.4'],
  ['283.5', '300.2'],
  ['283.5', '307'],
  ['283.5', '313.8'],
  ['283.5', '320.6'],
  ['283.5', '327.3'],
  ['283.5', '334.1'],
  ['283.5', '340.9'],
  ['283.5', '347.7'],
  ['283.5', '354.5'],
  ['283.5', '361.3'],
  ['276.8', '42.5'],
  ['276.8', '49.3'],
  ['276.8', '56'],
  ['276.8', '62.8'],
  ['276.8', '69.6'],
  ['276.8', '76.4'],
  ['276.8', '83.2'],
  ['276.8', '90'],
  ['276.8', '96.7'],
  ['276.8', '103.5'],
  ['276.8', '110.3'],
  ['276.8', '171.3'],
  ['276.8', '279.9'],
  ['276.8', '286.6'],
  ['276.8', '293.4'],
  ['276.8', '300.2'],
  ['276.8', '307'],
  ['276.8', '313.8'],
  ['276.8', '320.6'],
  ['276.8', '327.3'],
  ['276.8', '334.1'],
  ['276.8', '340.9'],
  ['276.8', '347.7'],
  ['276.8', '354.5'],
  ['276.8', '361.3'],
  ['276.8', '368'],
  ['270', '42.5'],
  ['270', '49.3'],
  ['270', '56'],
  ['270', '62.8'],
  ['270', '69.6'],
  ['270', '76.4'],
  ['270', '157.8'],
  ['270', '164.6'],
  ['270', '171.3'],
  ['270', '279.9'],
  ['270', '286.6'],
  ['270', '293.4'],
  ['270', '300.2'],
  ['270', '307'],
  ['270', '313.8'],
  ['270', '320.6'],
  ['270', '327.3'],
  ['270', '334.1'],
  ['270', '340.9'],
  ['270', '347.7'],
  ['270', '354.5'],
  ['270', '361.3'],
  ['270', '368'],
  ['270', '374.8'],
  ['263.2', '42.5'],
  ['263.2', '49.3'],
  ['263.2', '56'],
  ['263.2', '62.8'],
  ['263.2', '69.6'],
  ['263.2', '151'],
  ['263.2', '157.8'],
  ['263.2', '171.3'],
  ['263.2', '273.1'],
  ['263.2', '279.9'],
  ['263.2', '286.6'],
  ['263.2', '293.4'],
  ['263.2', '300.2'],
  ['263.2', '307'],
  ['263.2', '313.8'],
  ['263.2', '320.6'],
  ['263.2', '327.3'],
  ['263.2', '334.1'],
  ['263.2', '340.9'],
  ['263.2', '347.7'],
  ['263.2', '354.5'],
  ['263.2', '361.3'],
  ['263.2', '368'],
  ['263.2', '374.8'],
  ['263.2', '381.6'],
  ['256.4', '35.7'],
  ['256.4', '42.5'],
  ['256.4', '49.3'],
  ['256.4', '56'],
  ['256.4', '62.8'],
  ['256.4', '69.6'],
  ['256.4', '110.3'],
  ['256.4', '144.2'],
  ['256.4', '151'],
  ['256.4', '157.8'],
  ['256.4', '178.1'],
  ['256.4', '266.3'],
  ['256.4', '273.1'],
  ['256.4', '279.9'],
  ['256.4', '286.6'],
  ['256.4', '293.4'],
  ['256.4', '300.2'],
  ['256.4', '307'],
  ['256.4', '313.8'],
  ['256.4', '320.6'],
  ['256.4', '327.3'],
  ['256.4', '334.1'],
  ['256.4', '340.9'],
  ['256.4', '347.7'],
  ['256.4', '354.5'],
  ['256.4', '361.3'],
  ['256.4', '368'],
  ['256.4', '374.8'],
  ['256.4', '381.6'],
  ['256.4', '388.4'],
  ['249.6', '35.7'],
  ['249.6', '42.5'],
  ['249.6', '49.3'],
  ['249.6', '56'],
  ['249.6', '62.8'],
  ['249.6', '69.6'],
  ['249.6', '103.5'],
  ['249.6', '110.3'],
  ['249.6', '117.1'],
  ['249.6', '123.9'],
  ['249.6', '137.4'],
  ['249.6', '144.2'],
  ['249.6', '151'],
  ['249.6', '157.8'],
  ['249.6', '171.3'],
  ['249.6', '178.1'],
  ['249.6', '246'],
  ['249.6', '266.3'],
  ['249.6', '273.1'],
  ['249.6', '279.9'],
  ['249.6', '286.6'],
  ['249.6', '293.4'],
  ['249.6', '300.2'],
  ['249.6', '307'],
  ['249.6', '313.8'],
  ['249.6', '320.6'],
  ['249.6', '327.3'],
  ['249.6', '334.1'],
  ['249.6', '340.9'],
  ['249.6', '347.7'],
  ['249.6', '354.5'],
  ['249.6', '361.3'],
  ['249.6', '368'],
  ['249.6', '374.8'],
  ['249.6', '381.6'],
  ['249.6', '388.4'],
  ['249.6', '395.2'],
  ['249.6', '429.1'],
  ['242.8', '35.7'],
  ['242.8', '42.5'],
  ['242.8', '56'],
  ['242.8', '62.8'],
  ['242.8', '69.6'],
  ['242.8', '96.7'],
  ['242.8', '103.5'],
  ['242.8', '110.3'],
  ['242.8', '117.1'],
  ['242.8', '123.9'],
  ['242.8', '137.4'],
  ['242.8', '144.2'],
  ['242.8', '151'],
  ['242.8', '157.8'],
  ['242.8', '164.6'],
  ['242.8', '171.3'],
  ['242.8', '178.1'],
  ['242.8', '246'],
  ['242.8', '266.3'],
  ['242.8', '273.1'],
  ['242.8', '279.9'],
  ['242.8', '286.6'],
  ['242.8', '293.4'],
  ['242.8', '300.2'],
  ['242.8', '307'],
  ['242.8', '313.8'],
  ['242.8', '320.6'],
  ['242.8', '327.3'],
  ['242.8', '334.1'],
  ['242.8', '340.9'],
  ['242.8', '347.7'],
  ['242.8', '354.5'],
  ['242.8', '361.3'],
  ['242.8', '368'],
  ['242.8', '374.8'],
  ['242.8', '381.6'],
  ['242.8', '388.4'],
  ['242.8', '395.2'],
  ['242.8', '402'],
  ['242.8', '408.7'],
  ['242.8', '422.3'],
  ['242.8', '429.1'],
  ['236.1', '35.7'],
  ['236.1', '42.5'],
  ['236.1', '49.3'],
  ['236.1', '56'],
  ['236.1', '62.8'],
  ['236.1', '90'],
  ['236.1', '96.7'],
  ['236.1', '103.5'],
  ['236.1', '110.3'],
  ['236.1', '117.1'],
  ['236.1', '130.7'],
  ['236.1', '137.4'],
  ['236.1', '144.2'],
  ['236.1', '151'],
  ['236.1', '157.8'],
  ['236.1', '164.6'],
  ['236.1', '171.3'],
  ['236.1', '178.1'],
  ['236.1', '184.9'],
  ['236.1', '239.2'],
  ['236.1', '246'],
  ['236.1', '259.5'],
  ['236.1', '266.3'],
  ['236.1', '273.1'],
  ['236.1', '279.9'],
  ['236.1', '286.6'],
  ['236.1', '293.4'],
  ['236.1', '300.2'],
  ['236.1', '307'],
  ['236.1', '313.8'],
  ['236.1', '320.6'],
  ['236.1', '327.3'],
  ['236.1', '354.5'],
  ['236.1', '361.3'],
  ['236.1', '368'],
  ['236.1', '374.8'],
  ['236.1', '381.6'],
  ['236.1', '388.4'],
  ['236.1', '395.2'],
  ['236.1', '402'],
  ['236.1', '408.7'],
  ['236.1', '415.5'],
  ['236.1', '422.3'],
  ['236.1', '429.1'],
  ['229.3', '35.7'],
  ['229.3', '42.5'],
  ['229.3', '49.3'],
  ['229.3', '90'],
  ['229.3', '96.7'],
  ['229.3', '117.1'],
  ['229.3', '123.9'],
  ['229.3', '130.7'],
  ['229.3', '137.4'],
  ['229.3', '144.2'],
  ['229.3', '151'],
  ['229.3', '157.8'],
  ['229.3', '164.6'],
  ['229.3', '171.3'],
  ['229.3', '178.1'],
  ['229.3', '184.9'],
  ['229.3', '191.7'],
  ['229.3', '239.2'],
  ['229.3', '246'],
  ['229.3', '266.3'],
  ['229.3', '273.1'],
  ['229.3', '279.9'],
  ['229.3', '286.6'],
  ['229.3', '293.4'],
  ['229.3', '300.2'],
  ['229.3', '307'],
  ['229.3', '313.8'],
  ['229.3', '320.6'],
  ['229.3', '388.4'],
  ['229.3', '395.2'],
  ['229.3', '402'],
  ['229.3', '408.7'],
  ['229.3', '415.5'],
  ['229.3', '422.3'],
  ['222.5', '35.7'],
  ['222.5', '42.5'],
  ['222.5', '49.3'],
  ['222.5', '56'],
  ['222.5', '90'],
  ['222.5', '96.7'],
  ['222.5', '103.5'],
  ['222.5', '117.1'],
  ['222.5', '123.9'],
  ['222.5', '130.7'],
  ['222.5', '137.4'],
  ['222.5', '151'],
  ['222.5', '157.8'],
  ['222.5', '164.6'],
  ['222.5', '171.3'],
  ['222.5', '178.1'],
  ['222.5', '184.9'],
  ['222.5', '191.7'],
  ['222.5', '198.5'],
  ['222.5', '205.3'],
  ['222.5', '239.2'],
  ['222.5', '246'],
  ['222.5', '266.3'],
  ['222.5', '273.1'],
  ['222.5', '279.9'],
  ['222.5', '286.6'],
  ['222.5', '293.4'],
  ['222.5', '300.2'],
  ['222.5', '307'],
  ['222.5', '313.8'],
  ['215.7', '35.7'],
  ['215.7', '42.5'],
  ['215.7', '49.3'],
  ['215.7', '56'],
  ['215.7', '62.8'],
  ['215.7', '76.4'],
  ['215.7', '83.2'],
  ['215.7', '90'],
  ['215.7', '96.7'],
  ['215.7', '164.6'],
  ['215.7', '171.3'],
  ['215.7', '178.1'],
  ['215.7', '184.9'],
  ['215.7', '191.7'],
  ['215.7', '198.5'],
  ['215.7', '205.3'],
  ['215.7', '212'],
  ['215.7', '218.8'],
  ['215.7', '225.6'],
  ['215.7', '239.2'],
  ['215.7', '266.3'],
  ['215.7', '273.1'],
  ['215.7', '286.6'],
  ['215.7', '293.4'],
  ['215.7', '300.2'],
  ['208.9', '35.7'],
  ['208.9', '42.5'],
  ['208.9', '49.3'],
  ['208.9', '56'],
  ['208.9', '62.8'],
  ['208.9', '76.4'],
  ['208.9', '83.2'],
  ['208.9', '90'],
  ['208.9', '96.7'],
  ['208.9', '103.5'],
  ['208.9', '110.3'],
  ['208.9', '117.1'],
  ['208.9', '123.9'],
  ['208.9', '151'],
  ['208.9', '157.8'],
  ['208.9', '164.6'],
  ['208.9', '171.3'],
  ['208.9', '178.1'],
  ['208.9', '184.9'],
  ['208.9', '191.7'],
  ['208.9', '198.5'],
  ['208.9', '205.3'],
  ['208.9', '212'],
  ['208.9', '218.8'],
  ['208.9', '252.7'],
  ['208.9', '259.5'],
  ['208.9', '266.3'],
  ['202.2', '42.5'],
  ['202.2', '49.3'],
  ['202.2', '56'],
  ['202.2', '62.8'],
  ['202.2', '76.4'],
  ['202.2', '83.2'],
  ['202.2', '90'],
  ['202.2', '96.7'],
  ['202.2', '103.5'],
  ['202.2', '110.3'],
  ['202.2', '117.1'],
  ['202.2', '151'],
  ['202.2', '157.8'],
  ['202.2', '164.6'],
  ['202.2', '171.3'],
  ['202.2', '178.1'],
  ['202.2', '184.9'],
  ['202.2', '191.7'],
  ['202.2', '198.5'],
  ['202.2', '205.3'],
  ['202.2', '212'],
  ['202.2', '218.8'],
  ['202.2', '239.2'],
  ['202.2', '252.7'],
  ['202.2', '259.5'],
  ['202.2', '266.3'],
  ['195.4', '42.5'],
  ['195.4', '49.3'],
  ['195.4', '56'],
  ['195.4', '69.6'],
  ['195.4', '76.4'],
  ['195.4', '83.2'],
  ['195.4', '90'],
  ['195.4', '103.5'],
  ['195.4', '110.3'],
  ['195.4', '117.1'],
  ['195.4', '144.2'],
  ['195.4', '151'],
  ['195.4', '157.8'],
  ['195.4', '164.6'],
  ['195.4', '171.3'],
  ['195.4', '178.1'],
  ['195.4', '184.9'],
  ['195.4', '191.7'],
  ['195.4', '198.5'],
  ['195.4', '205.3'],
  ['195.4', '212'],
  ['195.4', '218.8'],
  ['195.4', '239.2'],
  ['195.4', '246'],
  ['195.4', '252.7'],
  ['195.4', '259.5'],
  ['188.6', '42.5'],
  ['188.6', '49.3'],
  ['188.6', '56'],
  ['188.6', '69.6'],
  ['188.6', '83.2'],
  ['188.6', '96.7'],
  ['188.6', '103.5'],
  ['188.6', '110.3'],
  ['188.6', '117.1'],
  ['188.6', '123.9'],
  ['188.6', '137.4'],
  ['188.6', '144.2'],
  ['188.6', '151'],
  ['188.6', '157.8'],
  ['188.6', '164.6'],
  ['188.6', '171.3'],
  ['188.6', '178.1'],
  ['188.6', '184.9'],
  ['188.6', '191.7'],
  ['188.6', '198.5'],
  ['188.6', '205.3'],
  ['188.6', '212'],
  ['188.6', '218.8'],
  ['188.6', '246'],
  ['188.6', '252.7'],
  ['181.8', '49.3'],
  ['181.8', '62.8'],
  ['181.8', '83.2'],
  ['181.8', '90'],
  ['181.8', '96.7'],
  ['181.8', '103.5'],
  ['181.8', '110.3'],
  ['181.8', '117.1'],
  ['181.8', '123.9'],
  ['181.8', '130.7'],
  ['181.8', '137.4'],
  ['181.8', '144.2'],
  ['181.8', '151'],
  ['181.8', '157.8'],
  ['181.8', '164.6'],
  ['181.8', '171.3'],
  ['181.8', '178.1'],
  ['181.8', '184.9'],
  ['181.8', '191.7'],
  ['181.8', '198.5'],
  ['181.8', '205.3'],
  ['181.8', '212'],
  ['181.8', '218.8'],
  ['181.8', '246'],
  ['181.8', '252.7'],
  ['175', '56'],
  ['175', '69.6'],
  ['175', '83.2'],
  ['175', '90'],
  ['175', '96.7'],
  ['175', '103.5'],
  ['175', '110.3'],
  ['175', '117.1'],
  ['175', '123.9'],
  ['175', '130.7'],
  ['175', '137.4'],
  ['175', '144.2'],
  ['175', '151'],
  ['175', '157.8'],
  ['175', '164.6'],
  ['175', '171.3'],
  ['175', '178.1'],
  ['175', '184.9'],
  ['175', '191.7'],
  ['175', '198.5'],
  ['175', '205.3'],
  ['175', '212'],
  ['175', '218.8'],
  ['175', '225.6'],
  ['175', '232.4'],
  ['175', '239.2'],
  ['175', '246'],
  ['175', '252.7'],
  ['168.2', '49.3'],
  ['168.2', '56'],
  ['168.2', '69.6'],
  ['168.2', '83.2'],
  ['168.2', '96.7'],
  ['168.2', '103.5'],
  ['168.2', '110.3'],
  ['168.2', '117.1'],
  ['168.2', '123.9'],
  ['168.2', '130.7'],
  ['168.2', '137.4'],
  ['168.2', '144.2'],
  ['168.2', '151'],
  ['168.2', '157.8'],
  ['168.2', '164.6'],
  ['168.2', '171.3'],
  ['168.2', '178.1'],
  ['168.2', '184.9'],
  ['168.2', '191.7'],
  ['168.2', '198.5'],
  ['168.2', '205.3'],
  ['168.2', '212'],
  ['168.2', '218.8'],
  ['168.2', '225.6'],
  ['168.2', '232.4'],
  ['168.2', '239.2'],
  ['168.2', '246'],
  ['161.5', '56'],
  ['161.5', '62.8'],
  ['161.5', '69.6'],
  ['161.5', '83.2'],
  ['161.5', '90'],
  ['161.5', '96.7'],
  ['161.5', '103.5'],
  ['161.5', '110.3'],
  ['161.5', '117.1'],
  ['161.5', '123.9'],
  ['161.5', '130.7'],
  ['161.5', '137.4'],
  ['161.5', '144.2'],
  ['161.5', '151'],
  ['161.5', '157.8'],
  ['161.5', '164.6'],
  ['161.5', '171.3'],
  ['161.5', '178.1'],
  ['161.5', '184.9'],
  ['161.5', '191.7'],
  ['161.5', '198.5'],
  ['161.5', '205.3'],
  ['161.5', '212'],
  ['161.5', '218.8'],
  ['161.5', '225.6'],
  ['161.5', '232.4'],
  ['161.5', '239.2'],
  ['161.5', '246'],
  ['154.7', '69.6'],
  ['154.7', '83.2'],
  ['154.7', '90'],
  ['154.7', '96.7'],
  ['154.7', '103.5'],
  ['154.7', '110.3'],
  ['154.7', '117.1'],
  ['154.7', '123.9'],
  ['154.7', '130.7'],
  ['154.7', '137.4'],
  ['154.7', '144.2'],
  ['154.7', '151'],
  ['154.7', '157.8'],
  ['154.7', '164.6'],
  ['154.7', '171.3'],
  ['154.7', '178.1'],
  ['154.7', '184.9'],
  ['154.7', '191.7'],
  ['154.7', '198.5'],
  ['154.7', '205.3'],
  ['154.7', '212'],
  ['154.7', '218.8'],
  ['154.7', '225.6'],
  ['154.7', '232.4'],
  ['147.9', '56'],
  ['147.9', '62.8'],
  ['147.9', '69.6'],
  ['147.9', '90'],
  ['147.9', '96.7'],
  ['147.9', '110.3'],
  ['147.9', '117.1'],
  ['147.9', '123.9'],
  ['147.9', '130.7'],
  ['147.9', '137.4'],
  ['147.9', '144.2'],
  ['147.9', '151'],
  ['147.9', '157.8'],
  ['147.9', '164.6'],
  ['147.9', '171.3'],
  ['147.9', '178.1'],
  ['147.9', '184.9'],
  ['147.9', '191.7'],
  ['147.9', '198.5'],
  ['147.9', '205.3'],
  ['147.9', '212'],
  ['147.9', '218.8'],
  ['147.9', '225.6'],
  ['147.9', '232.4'],
  ['141.1', '62.8'],
  ['141.1', '69.6'],
  ['141.1', '76.4'],
  ['141.1', '90'],
  ['141.1', '96.7'],
  ['141.1', '110.3'],
  ['141.1', '117.1'],
  ['141.1', '123.9'],
  ['141.1', '130.7'],
  ['141.1', '137.4'],
  ['141.1', '144.2'],
  ['141.1', '151'],
  ['141.1', '157.8'],
  ['141.1', '164.6'],
  ['141.1', '171.3'],
  ['141.1', '178.1'],
  ['141.1', '184.9'],
  ['141.1', '191.7'],
  ['141.1', '198.5'],
  ['141.1', '205.3'],
  ['141.1', '212'],
  ['141.1', '218.8'],
  ['141.1', '225.6'],
  ['134.3', '62.8'],
  ['134.3', '69.6'],
  ['134.3', '83.2'],
  ['134.3', '90'],
  ['134.3', '96.7'],
  ['134.3', '103.5'],
  ['134.3', '110.3'],
  ['134.3', '117.1'],
  ['134.3', '123.9'],
  ['134.3', '130.7'],
  ['134.3', '137.4'],
  ['134.3', '144.2'],
  ['134.3', '151'],
  ['134.3', '157.8'],
  ['134.3', '164.6'],
  ['134.3', '171.3'],
  ['134.3', '178.1'],
  ['134.3', '184.9'],
  ['134.3', '191.7'],
  ['134.3', '198.5'],
  ['134.3', '205.3'],
  ['134.3', '212'],
  ['127.5', '62.8'],
  ['127.5', '69.6'],
  ['127.5', '83.2'],
  ['127.5', '90'],
  ['127.5', '103.5'],
  ['127.5', '110.3'],
  ['127.5', '117.1'],
  ['127.5', '123.9'],
  ['127.5', '130.7'],
  ['127.5', '137.4'],
  ['127.5', '144.2'],
  ['127.5', '151'],
  ['127.5', '157.8'],
  ['127.5', '164.6'],
  ['127.5', '171.3'],
  ['127.5', '178.1'],
  ['127.5', '184.9'],
  ['127.5', '191.7'],
  ['127.5', '198.5'],
  ['127.5', '205.3'],
  ['120.8', '69.6'],
  ['120.8', '76.4'],
  ['120.8', '83.2'],
  ['120.8', '90'],
  ['120.8', '96.7'],
  ['120.8', '103.5'],
  ['120.8', '110.3'],
  ['120.8', '117.1'],
  ['120.8', '123.9'],
  ['120.8', '130.7'],
  ['120.8', '137.4'],
  ['120.8', '144.2'],
  ['120.8', '151'],
  ['120.8', '157.8'],
  ['120.8', '164.6'],
  ['120.8', '171.3'],
  ['120.8', '178.1'],
  ['120.8', '184.9'],
  ['120.8', '191.7'],
  ['120.8', '198.5'],
  ['114', '83.2'],
  ['114', '90'],
  ['114', '96.7'],
  ['114', '103.5'],
  ['114', '110.3'],
  ['114', '117.1'],
  ['114', '123.9'],
  ['114', '130.7'],
  ['114', '137.4'],
  ['114', '144.2'],
  ['114', '151'],
  ['114', '157.8'],
  ['114', '164.6'],
  ['107.2', '96.7'],
  ['107.2', '103.5'],
  ['107.2', '110.3'],
  ['107.2', '117.1'],
  ['107.2', '123.9'],
  ['107.2', '130.7'],
  ['107.2', '137.4'],
  ['107.2', '144.2'],
  ['107.2', '151'],
  ['107.2', '157.8'],
  ['107.2', '164.6'],
  ['100.4', '96.7'],
  ['100.4', '103.5'],
  ['100.4', '110.3'],
  ['100.4', '117.1'],
  ['100.4', '123.9'],
  ['100.4', '130.7'],
  ['100.4', '137.4'],
  ['100.4', '144.2'],
  ['100.4', '151'],
  ['93.6', '103.5'],
  ['93.6', '110.3'],
  ['93.6', '117.1'],
  ['93.6', '123.9'],
  ['93.6', '130.7'],
  ['93.6', '137.4'],
  ['93.6', '144.2'],
  ['93.6', '151'],
  ['86.9', '103.5'],
  ['86.9', '110.3'],
  ['86.9', '117.1'],
  ['86.9', '123.9'],
  ['86.9', '130.7'],
  ['86.9', '137.4'],
  ['80.1', '96.7'],
  ['80.1', '103.5'],
  ['80.1', '110.3'],
  ['80.1', '117.1'],
  ['80.1', '123.9'],
  ['80.1', '130.7'],
  ['73.3', '96.7'],
  ['73.3', '103.5'],
  ['73.3', '110.3'],
  ['73.3', '117.1'],
  ['73.3', '123.9'],
  ['73.3', '130.7'],
  ['66.5', '96.7'],
  ['66.5', '103.5'],
  ['66.5', '110.3'],
  ['66.5', '117.1'],
  ['66.5', '123.9'],
  ['66.5', '130.7'],
  ['59.7', '96.7'],
  ['59.7', '103.5'],
  ['59.7', '110.3'],
  ['59.7', '117.1'],
  ['59.7', '123.9'],
  ['59.7', '130.7'],
  ['52.9', '96.7'],
  ['52.9', '103.5'],
  ['52.9', '110.3'],
  ['52.9', '117.1'],
  ['52.9', '123.9'],
  ['52.9', '130.7'],
  ['52.9', '137.4'],
  ['46.2', '90'],
  ['46.2', '96.7'],
  ['46.2', '103.5'],
  ['46.2', '110.3'],
  ['46.2', '117.1'],
  ['46.2', '123.9'],
  ['46.2', '130.7'],
  ['46.2', '137.4'],
  ['46.2', '144.2'],
  ['39.4', '90'],
  ['39.4', '96.7'],
  ['39.4', '103.5'],
  ['39.4', '110.3'],
  ['39.4', '117.1'],
  ['39.4', '123.9'],
  ['39.4', '130.7'],
  ['39.4', '137.4'],
  ['39.4', '144.2'],
  ['32.6', '96.7'],
  ['32.6', '103.5'],
  ['32.6', '110.3'],
  ['32.6', '117.1'],
  ['32.6', '123.9'],
  ['32.6', '130.7'],
  ['32.6', '137.4'],
  ['32.6', '144.2'],
  ['32.6', '151'],
  ['25.8', '103.5'],
  ['25.8', '110.3'],
  ['25.8', '117.1'],
  ['25.8', '123.9'],
  ['25.8', '130.7'],
  ['25.8', '151'],
  ['19', '103.5'],
  ['19', '110.3'],
  ['19', '117.1'],
  ['19', '151'],
  ['19', '157.8'],
]